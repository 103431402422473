.App {
  text-align: center;
}
.card-padding {
  padding: 1.5rem 1.5rem 0;
}
.card {
  height: 100%;
}

.buttons-padding {
  padding: 0 1.5rem 1.5rem;
}

.editable-buttons {
  float: left;
  margin-top: 1.5rem;
}

.readonly-sickcase {
  background-color: darkgray;
  margin: 5px;
}

.v-centered {
  display: flex;
  justify-content: center;
  text-align: center;
}
.card-image img,
.card-image video {
  max-height: 200px;
}

.card-content {
  background-color: white;
}
.sickcase-view {
  background-color: lightgray;
  margin: 20px;
  border-radius: 20px;
}
